<template>
  <div class="responsive-modal" :class="[open ? 'show' : 'hide']">
    <div class="blur" @click="onOutsideClick"></div>
    <div class="responsive-modal-body" :class="{ animate: animate }" :id="id">
      <div class="body-container">
        <div class="close-button-container" @click="onClose">
          <div class="close-button">
            <img class="close-icon" src="@/assets/icons/ClosePopup.svg" />
          </div>
        </div>
        <slot name="modal-content"> </slot>
        <!-- <Loading v-if="loading" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  events: ["onClose"],
  // components: {
  // Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  // },
  props: ["open", "sticky", "id", "loading"],
  data() {
    return {
      isMobile: false,
      animate: false,
    };
  },
  mounted() {
    let windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      this.isMobile = true;
    }
    this.$emit("isMobile", this.isMobile);
    window.onresize = () => {
      let windowWidth = window.innerWidth;
      if (windowWidth < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      this.$emit("isMobile", this.isMobile);
    };
  },
  methods: {
    onClose() {
      this.animate = false;
      document.body.style.overflow = "visible";
      setTimeout(() => {
        this.$emit("onClose");
      }, 300);
    },
    onOutsideClick() {
      if (!this.sticky) {
        this.animate = false;
        document.body.style.overflow = "visible";
        setTimeout(() => {
          this.$emit("onClose");
        }, 300);
      }
    },
  },
  watch: {
    open(newValue, oldValue) {
      setTimeout(() => {
        if (newValue) {
          //  document.body.classList.add("no-scroll");
          //document.body.style.position = 'fixed';
          document.body.style.overflow = "hidden";
          //   document.body.style.top = `-${window.scrollY}px`;
          this.animate = true;
        } else {
          // document.body.classList.remove("no-scroll");
          this.animate = false;
          //const scrollY = document.body.style.top;
          //  document.body.style.position = 'static';
          document.body.style.overflow = "visible";

          //      document.body.style.top = '';
          //    window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./ResponsiveModalNew.scss"
</style>
